import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';

import { SITEMAP, SiteMap } from '../../_commons/sitemap';
import { SHOP_ADDRESSES } from '../../_commons/constants';
import { Address } from '../../_commons/models/address';
import { OrderedProduct } from '../../_commons/models/ordered-product';
import { Promotion } from '../../_commons/models/promotion';

import { ReplacePipe } from '../../_commons/pipes/replace/replace.pipe';
import { ToPricePipe } from '../../_commons/pipes/to-price/to-price.pipe';

import { CartService } from '../../_commons/services/cart/cart.service';
import { CheckoutService } from '../../_commons/services/checkout/checkout.service';
import { ColissimoPriceService } from '../../_commons/services/colissimo-price/colissimo-price.service';
import { ColissimoWeight } from '../../_commons/models/colissimo-weight';

@Component({
	selector: 'app-summary-cart',
	standalone: true,
	imports: [
		RouterModule,
		MatBadgeModule,
		MatExpansionModule,
		ReplacePipe,
		ToPricePipe
	],
	templateUrl: './summary-cart.component.html',
	styleUrl: './summary-cart.component.scss'
})
export class SummaryCartComponent implements OnInit {

	public sitemap: SiteMap = SITEMAP;
	public cartItems: OrderedProduct[] = [];

	public subTotal: number = 0;
	public shipingCharge: number = 0;
	public tax: number = 0.055;
	public total: number = 0;
	public promotion: Promotion | null = null;

	private shopAddress: Address = SHOP_ADDRESSES[0];

	constructor(
		private _cart: CartService,
		private _checkout: CheckoutService,
		private _colissimoPrice: ColissimoPriceService
	) {}

	public ngOnInit(): void {
		this.cartItems = this._cart.getCartItems();

		this._cart.currentCart.subscribe((cartItems: OrderedProduct[]) => {
			this.cartItems = cartItems;
		});

		this._checkout.currentDeliveredAddress.subscribe(async (address: Address | null) => {
			this.subTotal = this.cartItems.length ? this.cartSum() : 0;
			this.shipingCharge = await this.shipSum(address!);
			this.total = this.promotion
				? ((this.promotion && ((this.subTotal - this.promotion.promotion) < 0)) ? 0 : this.subTotal - this.promotion.promotion) + this.shipingCharge
				: this.subTotal + this.shipingCharge
		});

		this._checkout.currentPromotion.subscribe((promotion: Promotion | null) => {
			this.promotion = promotion;
			this.total = this.promotion
				? ((this.promotion && ((this.subTotal - this.promotion.promotion) < 0)) ? 0 : this.subTotal - this.promotion.promotion) + this.shipingCharge
				: this.subTotal + this.shipingCharge
		});
	}

	public productSum = (orderedProduct: OrderedProduct): number => orderedProduct.quantity * orderedProduct.price.value;
	public taxSum = (): number => this.cartItems
		.map((o: OrderedProduct) => (o.price.value * this.tax) * o.quantity)
		.reduce((a: number, b: number) => a + b);

	private cartSum = (): number => this.cartItems
		.map((orderedProduct: OrderedProduct) => orderedProduct.quantity * orderedProduct.price.value)
		.reduce((a: number, b: number) => a + b);

	private async shipSum(address: Address): Promise<number> {
		const cartWeight: number = this.cartItems
			.map((o: OrderedProduct) => (o.price.weight + o.price.container.weight) * o.quantity)
			.reduce((a: number, b: number) => a + b);

		return (this.shopAddress.street === address?.street)
			? 0
			: (await this._colissimoPrice.getColissimoPrices()).find((cw: ColissimoWeight) => cw.weight >= cartWeight)?.priceHome!;
	};

}
