<div class="cb-checkout-start">
	<div class="cb-checkout-start-summary-cart" #powSummaryCart>
		<app-summary-cart></app-summary-cart>
	</div>

	<div class="cb-checkout-start-content">
		<h2>Livraison</h2>

		<div class="cb-checkout-start-addresses">
			<div class="cb-checkout-title">
				<h3>Choisissez une adresse de livraison</h3>
			</div>

			<app-cb-address-selector
				[addresses]="this.addresses"
				[address]="this.deliveredAddress"
				(addressEvent)="this.personnalAddress = $event; this.updateDeliveredAddress($event)"
				[style.display]="this.options === 1 ? 'block' : 'none'"
			></app-cb-address-selector>

			<div [style.display]="this.options === 2 ? 'block' : 'none'">
				<mat-form-field appearance="outline">
					<mat-label>Code Postal</mat-label>
					<input matInput name="zip" type="text" [(ngModel)]="this.zip" />
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Ville</mat-label>
					<input matInput name="city" type="text" [(ngModel)]="this.city" />
				</mat-form-field>

				<mat-form-field appearance="outline">
					<mat-label>Rue</mat-label>
					<input matInput name="street" type="text" [(ngModel)]="this.street" />
				</mat-form-field>

				<button mat-flat-button color="accent" (click)="this.relayPointSelected(this.zip, this.city)">Trouver</button>

				<p [style.display]="this.isLoading ? 'block' : 'none'">Chargement des adresses en cours...</p>

				@defer() {
				<app-cb-map></app-cb-map>
				}@error {
				<div class="error-message">
					<p>Une erreur s'est produite lors du chargement de la carte. Veuillez réessayer plus tard.</p>
				</div>
				}

				<app-cb-address-selector
					[address]="this.deliveredAddress"
					[addresses]="this.relayAddresses"
					[options]="false"
					[addAddress]="false"
					(addressEvent)="this.personnalAddress = $event; this.updateDeliveredAddress($event)"
				></app-cb-address-selector>
			</div>

			<div [style.display]="this.options === 3 ? 'block' : 'none'">Livraison en Magasin</div>
		</div>

		<div class="cb-checkout-start-delivery-options">
			<div class="cb-checkout-title">
				<h3>Choisissez une option de livraison</h3>
				<mat-icon>help</mat-icon>
			</div>

			<mat-radio-group class="cb-checkout-start-radio-buttons" type="number" [(ngModel)]="this.options">
				<mat-radio-button name="options" [value]="1" (change)="this.updateDeliveredAddress(this.personnalAddress)">
					<p class="pow-choices-info">Livraison à domicile</p>
					<mat-icon class="pow-icon mdi" svgIcon="colissimo"></mat-icon>
				</mat-radio-button>

				<mat-radio-button
					name="options"
					[value]="2"
					[matTooltip]="!this.addresses.length ? 'Vous devez enregistrer au moins une adresse par défaut' : ''"
					[matTooltipPositionAtOrigin]="true"
					[disabled]="!this.addresses.length"
					(change)="this.relayPointSelected(this.personnalAddress.zip, this.personnalAddress.city)"
				>
					<p class="pow-choices-info">Livraison en point relais</p>
					<mat-icon class="pow-icon mdi" svgIcon="mondialrelay"></mat-icon>
				</mat-radio-button>

				<mat-radio-button name="options" [value]="3" (change)="this.updateDeliveredAddress(this.shopAddress)">
					<p class="pow-choices-info">Retrait en magasin à Grenade (0 €)</p>
				</mat-radio-button>
			</mat-radio-group>
		</div>

		<div class="cb-checkout-button">
			<app-cb-button
				color="primary"
				text="suivant"
				[matTooltip]="this.options === 1 && !this.addresses.length ? 'Vous devez enregistrer au moins une adresse par défaut pour continuer' : ''"
				[matTooltipPositionAtOrigin]="true"
				[disabled]="!this.options || this.isLoading || !this.deliveredAddress"
				[loading]="this.isLoading"
				[routerLink]="[this.sitemap.checkoutReady.route]"
			></app-cb-button>
		</div>
	</div>
</div>
