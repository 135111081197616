import { Component } from "@angular/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialog } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltip } from "@angular/material/tooltip";
import deepEqual from "deep-equal";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import { SHOP_ADDRESSES } from "../../_commons/constants";
import { Address } from "../../_commons/models/address";
import { Order } from "../../_commons/models/order";
import { OrderStatus } from "../../_commons/models/order-status";
import { OrderedProduct } from "../../_commons/models/ordered-product";

import { SearchOrderPipe } from "../../_commons/pipes/search-order/search-order.pipe";
import { ToAddressPipe } from "../../_commons/pipes/to-address/to-address.pipe";
import { ToDatePipe } from "../../_commons/pipes/to-date/to-date.pipe";
import { ToPricePipe } from "../../_commons/pipes/to-price/to-price.pipe";

import { AccountService } from "../../_commons/services/account/account.service";
import { OrderService } from "../../_commons/services/order/order.service";
import { OrderStatusService } from "../../_commons/services/order-status/order-status.service";
import { ApiDeliveryService } from "../../_commons/services/api/api-delivery/api-delivery.service";

import { CbOrderStatusProgressComponent } from "../../_commons/components/cb-order-status-progress/cb-order-status-progress.component";
import { AdminOrdersModalComponent } from "./admin-orders-modal/admin-orders-modal.component";
import { AdminOrdersItemComponent } from "./admin-orders-item/admin-orders-item.component";
import { MondialRelayResponse } from "../../_commons/models/mondial-relay/mondial-relay-response";

@Component({
	selector: "app-admin-orders",
	standalone: true,
	imports: [
		MatBadgeModule,
		MatCardModule,
		MatCheckboxModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatTooltip,
		SearchOrderPipe,
		ToAddressPipe,
		ToDatePipe,
		ToPricePipe,
		CbOrderStatusProgressComponent,
		AdminOrdersItemComponent,
	],
	templateUrl: "./admin-orders.component.html",
	styleUrl: "./admin-orders.component.scss",
	providers: [],
})
export class AdminOrdersComponent {
	private token: string = "";
	public orders!: Order[];
	public orderStatuses: OrderStatus[] = [];
	public userAddresses: Address[] = [];
	public isLoading: boolean = false;

	constructor(
		private _order: OrderService,
		private _orderStatus: OrderStatusService,
		private _delivery: ApiDeliveryService,
		private _account: AccountService,
		public dialog: MatDialog
	) {
		this.getOrders();
		this.getUserAddresses();

		this._account.currentUserToken.subscribe((token: string | null) => (this.token = token || ""));
		this._orderStatus.currentOrderStatuses.subscribe((orderStatus: OrderStatus[]) => (this.orderStatuses = orderStatus));
		this._order.currentOrders.subscribe((orders: Order[]) => (this.orders = orders));
	}

	private getUserAddresses(): void {
		const user = this._account.getUser();
		if (user && user.profile) {
			this.userAddresses = user.profile.addresses;
		}
	}

	private async getOrders(): Promise<void> {
		this.isLoading = true;
		this.orders = await this._order.getOrders();
		this.isLoading = false;
	}

	public isShopAddress = (address: string): boolean => deepEqual(<Address>JSON.parse(address), SHOP_ADDRESSES[0]);
	public isUserProfileAddress = (address: string): boolean => {
		const parsedAddress = <Address>JSON.parse(address);
		return this.userAddresses.some((userAddress) => deepEqual(parsedAddress, userAddress));
	};

	public openDialog(order: Order): void {
		this.dialog.open(AdminOrdersModalComponent, {
			data: order,
		});
	}

	public exportToExcel(order: Order): void {
		const formattedData = order.cart.orderedProducts.map((item) => ({
			id: item.id,
			quantity: item.quantity,
			product: item.product.name,
			price: item.price.value,
			amount: item.quantity * item.price.value,
		}));

		const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "OrderedProducts");

		const wbout: Blob = new Blob([XLSX.write(wb, { bookType: "xlsx", type: "array" })], { type: "application/octet-stream" });

		saveAs(wbout, `CARREMENT_BON_${order.id}.xlsx`);
	}

	public async downloadColissimo(order: Order): Promise<void> {
		const blob: Blob = (
			await this._delivery.generateLabel(this.token, {
				reference: order.reference,
				weight: order.cart.orderedProducts.map((o: OrderedProduct) => o.price.weight * o.quantity).reduce((a: number, b: number) => a + b),
				deliveredAddress: <Address>JSON.parse(order.deliveredAddress),
			})
		)?.body!;

		const blobURL: string = window.URL.createObjectURL(blob);
		const downloadLink: HTMLAnchorElement = document.createElement("a");

		downloadLink.href = blobURL;
		downloadLink.setAttribute("download", `COLISSIMO_CARREMENT_BON_${order.id}.pdf`);
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
		window.URL.revokeObjectURL(blobURL);
	}

	public async downloadMondialRelay(order: Order): Promise<void> {
		const deliveredAddress: Address = <Address>JSON.parse(order.deliveredAddress);

		const response: MondialRelayResponse = (await this._delivery.generateMondialRelay(this.token, {
				PRID: deliveredAddress.MRID!,
				orderId: order.id,
				reference: order.reference,
				weight: order.cart.orderedProducts.map((o: OrderedProduct) => o.price.weight * o.quantity).reduce((a: number, b: number) => a + b),
				deliveredAddress,
			})
		)?.body!;

		const downloadLink: HTMLAnchorElement = document.createElement("a");
		downloadLink.href = response.url!;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}
}
